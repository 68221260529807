import React from "react";
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import PSNC_Logo from "./assets/PSNC_logo.svg";
import "./welcome-page.css";

function Copyright() {
  const { t } = useTranslation();
  const currentLanguage = i18n.language;
  const psncButton = t("PSNClink"); //"https://www.psnc.pl/";
  const mslLink= currentLanguage === 'pl' ?"/konwerter/autorzy": "/converter/authors"

  return (
    <div className="copyright-container">
      <a className="copyright-button" href={psncButton}>
        <img src={PSNC_Logo} alt="PSNC Logo" className="copyright-logo" />
        <div className="copyright-text">
          {t("PSNC")}
          <br />
          {t("ICHB")}
        </div>
      </a>
      <a className="footer-msl">Media Solutions Lab</a>
    </div>
  );
}

export default Copyright;
